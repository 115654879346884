.data-unit {
    min-width: 150px;
    
    &__title {
        font-size: 1.02rem;
        color: #5F5F5F;
        margin-bottom: 4px;
    }
    
    &__value {
        font-size: 1.17rem;
        color: #000000;
    }
}