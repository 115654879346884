.sidebar {
    display: inline-block;
    width: 250px;
    position: -webkit-sticky; 
    position: sticky;
    margin-right: 15px;
    vertical-align: top;
    top: 10px;
    
    &__link {
        text-decoration: none;
    }
    
    &__item {
        width: 100%;
        margin-bottom: 15px;
        padding: 13px 25px;
        border-radius: 29px;
        display: flex;
        align-items: center;
        transition: .2s all linear;
        
        &:hover {
            background: #F4F4F4;
        }
        
        &_active {
            background: #E0E0E4;
            
            &:hover {
                background: #E0E0E4;
            }
        }
    }
    
    &__img {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        background-position: center center;
    }
    
    &__text {
        display: inline-block;
        margin-left: 17px;
        font-size: 1.4rem;
    }
    
    &__create {
        width: 250px;
        font-weight: normal;
        cursor: pointer;
        border-radius: 26px;
        margin-top: 25px;
        padding: 16px 20px;
        font-size: 1.35rem;
    }
}