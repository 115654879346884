.footer {
    background: #4F4F4F;
    color: #ffffff;
    padding: 70px 0;
    text-align: center;
    width: 100%;
    
    &__name {
        font-size: 2rem;
        margin-bottom: 65px;
    }
    
    &__author {
        font-size: 1.3rem;
    }
}