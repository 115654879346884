.label {
    padding: 8px 28px;
    border-radius: 72px;
    font-size: 1rem;
    background: transparent;
    
    &__mini {
        width: fit-content;
        padding: 6px 22px;
        font-size: .9rem;
        display: inline-block;
    }
}
