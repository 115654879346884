.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: animate;
    animation-timing-function: linear;
    background: #d0d0d0;
    background: linear-gradient(to right, #F1F1F1 8%, #D9D9D9 18%, #F2F2F2 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

@keyframes animate {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

.loading-topic {
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    &__item {
        padding: 25px 10px 25px 15px;
        display: flex;
        height: 119.22px;
        margin-bottom: 15px;
        border-radius: 11px;
    }
    
    &__item--shadow {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    }
    
    &__item--padding {
        padding-top: 0;
        padding-left: 0;
        height: auto;
    }
    
    &__item--padding_margin {
        padding: 0;
        height: auto;
    }
    
    &__img {
        height: 62px;
        width: 62px;
        border-radius: 7px;
        @extend .animated-background;
    }
    
    &__user-info {
        width: 300px;
        height: 12px;
        margin-left: 15px;
        border-radius: 2px;
        @extend .animated-background;
    }
    
    &__title {
        width: 400px;
        height: 18px;
        margin-top: 40px;
        border-radius: 4px;
        @extend .animated-background;
    }
    
    &__info {
        width: 136px;
        height: 33px;
        margin-top: -10px;
        margin-left: 145px;
        border-radius: 72px;
        @extend .animated-background;
    }
    
    &__big--image {
        width: 450px;
        height: 250px;
        border-radius: 5px;
        margin-top: 15px;
        margin-left: 18%;
        @extend .animated-background;
    }
    
    &__information {
        width: 110px;
        height: 15px;
        border-radius: 4px;
        @extend .animated-background;
        
        &--value {
            margin-top: 9px;
            width: 180px;
        }
    }
    
    &__wrapper {
        display: flex;
        margin-top: 30px;
     
        & > div {
            margin-right: 55px;
        }
    }

    &__long {
        width: 600px;
        margin-top: 0;
    }
}