.modal {
    padding-right: 15px!important;
}

.modal-create {
    width: 1141px;
    max-width: 1141px!important;
    
    .modal-header {
        font-size: 1.9rem;
        font-weight: bold;
    }
    
    .close {
        font-size: 2.8rem;
        
        &:hover {
            cursor: pointer;
        }
    }
    
    &__body {
        padding: 25px 0;
    }
    
    &__textarea {
        textarea {
            width: 100%;
            min-height: 219px;
            margin: 5px 0 0 0;
            padding: 5px;
            resize: none;
        }
    }
    
    &__btn-wrapper {
        padding-top: 20px;
    }
    
    &__error {
        margin-top: 20px;
        color: #E9041E;
    }
    
    &__info {
        color: #035773;
    }
    
    &__images {
        margin-top: 25px;
    }
    
    &__btn {
        background: #6BCAA8;
        padding: 12px 38px; 
        transition: .5s all ease-out;
        
        &:hover {
            cursor: pointer;
            background: #47927a;
        }
    }
}