.search {
    background-color: #EAFAF4;
    border-radius: 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    padding: 97px 140px 107px 140px;
    margin-top: 15px;
    margin-bottom: 25px;

    &__text {
        font-size: 2.15rem;
        font-weight: bold;
        margin-bottom: 14px;
    }

    &__input {
        font-family: $fontFamily;
        width: 100%;
        font-size: 1.3rem;
        border: 2px solid transparent;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 14px;
        padding: 16px 20px 16px 65px;
        transition: .2s linear;

        &:focus, &:active {
            border: 2px solid #72a093;
            outline: none;
        }

        &::placeholder {
            color: #7D7D7D;
            font-size: 1.23rem;
            font-weight: normal;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__img {
        position: absolute;
        left: 23px;
        top: 15px;
    }
}