.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 0;
}

.logo {
    display: flex;
    align-items: center;
    
    &__name {
        font-weight: bold;
        font-size: 1.867rem;
        padding-left: 14px;
        color: $greenForText
    }
}

.auth {
    margin-left: auto;
    max-width: 750px;

    &__title {
        font-size: 1.9rem;
        font-weight: bold;
        line-height: 1.5;
        padding-top: 25px;
        text-align: center;
        
        span:hover {
            cursor: pointer;
        }
    }
    
    &__field {
        margin: 0 auto;

        input, label {
            max-width: 324px;
        }
    }
    
    &__error {
        text-align: center;
        color: crimson;
    }
}

.popup {
    position: relative;
    z-index: 10;
    max-width: 160px;
    right: -491px;
    top: 91px;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.30);

    .arrow-up {
        position: absolute;
        top: -13px; right: 6px;
        z-index: 9;
        width: 0;
        height: 0;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 14px solid #ffffff;
    }
    
    .modal-content {
        border-radius: 10px;
    }
    
    &__container {
        padding: 10px 0;
        
        li {
            padding: 8px 0;
            font-size: 1.05rem;
            text-align: center;
            
            &:hover {
                background: rgb(3, 102, 214);
                color: #ffffff;
                cursor: pointer;
            }
        }
    }
}