.slider {
    padding: 50px 0;
    
    &__items {
        position: relative;
        padding-bottom: 25px;
        padding-right: 72px;
        user-select: none;
    }
    
    &__item {
        display: none;
        text-align: center;
        transition: transform 0.6s ease-in-out;
        
        &-selected {
            display: block;
        }
        
        img {
            border-radius: 5px;
            max-width: 450px;
        }
    }
    
    &__preview {
        text-align: center;
        padding-right: 62px;
        
        & > div {
            display: inline-block;
            padding: 0 8px;
            
            img {
                border: 2px solid transparent;
                max-width: 70px;
                max-height: 70px;
                border-radius: 5px;
                
                &:hover {
                    cursor: pointer;
                }
            }
        }
        
        &-selected {
            img {
                border: 2px solid #52796F!important;
            }
        }
    }


    &__svg {
        &:hover {
            cursor: pointer;
            
            path {
                fill: #4e4e4e;
            }
        }
    }
    
    &__arrow {
        position: absolute;
        top: 108px;
        
        &-left {
            left: 48px
        }
        
        &-right {
            right: 121px;
        }
    }
}