.topics {
    display: inline-block;
    vertical-align: bottom;
    min-height: 372px;
    width: 100%;
    
    &__title {
        margin-bottom: 25px;
        
        & > div {
            margin-top: 11px;
            width: 426px;
        }
    }
    
    &__not-found {
        font-size: 1.4rem;
        color: #4F4F4F;
        text-align: center;
        margin-bottom: 10px;
    }
}

.topic {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    border-radius: 11px;
    padding: 25px 10px 25px 10px;
    margin-bottom: 15px;
    transition: .2s all linear;
    
    &:hover {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
    }
    
    &__user {
        display: flex;
        align-items: flex-start;
        
        img {
            width: 62px;
            height: 62px;
            border-radius: 15%;
            object-fit: cover;
            margin-right: 10px;
        }
    }
    
    &__user-info {
        display: flex;
        align-items: center;
        font-size: 1.06rem;
        width: 100%;
        color: #5F5F5F;
        
        &_topic {
            width: 80%;
        }
    }
    
    &__edit-btn {
        background: #9A9E99;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    
    &__time {
        display: inline-block;
        min-width: 420px;
        margin-right: 45px;
    }
    
    &__titles {
        margin-top: -15px;
        margin-left: 72px;
        
        &--news {
            margin-top: 0;
        }
    }
    
    &__mini-labels {
        margin-top: 9px;
        
        & > div {
            margin-right: 13px;
        }
    }
    
    &__title {
        font-size: 1.25rem;
        font-weight: bold;
    }
    
}

.metrics {
    font-family: Arial, serif;
    display: flex;
    margin-left: auto;
    margin-right: 25px;
    
    &__comments, &__views {
        display: flex;
        align-items: center;
    }
    
    &__numbers {
        display: inline-block;
        min-width: 40px;
    }
    
    img {
        margin-right: 8px;
        width: 22px;
        height: 24px;
        border-radius: 0;
    }
}