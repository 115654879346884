.accordion {
    padding: 30px 20px 30px 60px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    border-radius: 11px;
    margin-bottom: 15px;
    
    &__header {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }
    
    &__btn {
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;
        color: transparent;
        outline: none;
        
        img {
            width: 18px;
            height: 18px;
        }
    }
    
    &__title {
        font-size: 1.3rem;
        color: #000000;
        font-weight: bold;
        display: inline-block;
        margin-left: 35px;
    }
    
    &__text {
        font-size: 1.07rem;
        color: #2c3136;
        margin-top: 30px;
        margin-left: 53px;
    }
}