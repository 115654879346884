.article {
    display: inline-block;
    vertical-align: bottom;
    min-height: 472px;
    width: 100%;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    border-radius: 11px;
    padding: 25px 12px 50px 12px;
    transition: .2s all linear;

    &:hover {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    }
    
    &__container {
        padding-left: 72px;
    }
    
    &__address {
        font-family: Arial, sans-serif;
        color: #000000;
        font-size: 1.05rem;
        display: flex;
        align-items: center;
    }
    
    &__tooltip {
        font-size: .8rem;
        
        &-wrapper {
            margin-left: 20px;
        }
    }
    
    &__time {
        min-width: inherit;
        margin-right: 30px;
        font-size: 1.2rem;
    }
    
    &__underground {
        padding-bottom: 22px;
    }
    
    &__title {
        font-size: 1.35rem;
        font-weight: bold;
        margin: 20px 0;
    }
    
    &__mini-wrapper {
        padding-left: 50px;
    }
    
    &__text {
        color: #17231D;
        font-size: 1.08rem;
    }
    
    &__separator {
        border-bottom: 1px solid #7D7D7D;
        margin-top: 30px;
        margin-bottom: 45px;
        margin-right: 72px;
    }
    
    &__maps {
        margin-top: 20px;
    }
    
    &__count {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 18px;
    }
}

.article-info {
    &__items {
        margin-bottom: 25px;
    }
    
    &__items > div {
        margin-right: 100px;
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    
}

.comment {
    margin-bottom: 25px;
    
    &__main {
        display: flex;
        
        img {
            width: 62px;
            height: 62px;
            border-radius: 15%;
            object-fit: cover;
            margin-right: 10px;
        }
    }
    
    &__parent {
        padding-left: 72px;
    }
    
    &__info {
        width: 100%;
        background: #F5F5F5;
        border-radius: 11px;
        padding: 11px 19px;
    }
    
    &__user {
        color: #5F5F5F;
        font-size: 1.1rem;
        font-family: Arial, sans-serif;
        margin-bottom: 15px;
    }
    
    &__comment {
        color: #17231D;
    }
    
    &__answer {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 1.05rem;
        color: #5C5C5C;
        background: none;
        border: none;
        
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        
        &:active, &:focus {
            outline: none;
        }
    }
    
    &__form {
        margin-top: 12px;
        margin-left: 72px;
        background: #F5F5F5;
        border-radius: 11px;
        padding: 11px 19px;
        
        span {
            color: #292929;
            font-size: 1.03rem;
        }
        
        textarea {
            width: 100%;
            min-height: 119px;
            margin: 5px 0 0 0;
            padding: 5px 9px;
            resize: none;
        }
        
        &_main {
            margin-left: 0;
            margin-bottom: 22px;
        }
    }
    
    &__buttons {
        margin-top: 8px;
    }
    
    &__button {
        padding: 8px 30px;
        margin-right: 9px;
        
        &:last-child {
            margin-right: 0;
        }
        
        &-send {
            background: #6BCAA8;
        }
        
        &-reject {
            background: #C36868;
            
            &:hover {
                background: #b33d3d;
            }
        }
    }
}

.underground {
    
    &__item {
        display: inline-flex;
        align-items: center;
        color: #5F5F5F;
        margin-right: 40px;
        
        span {
            display: inline-block;
            vertical-align: top;
        }
        
        &:before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 10px;
            border-radius: 50%;
        }
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    &__distance {
        font-family: Arial, sans-serif;
    }
    
    .red:before {
        background: #E42313;
    }
    
    .blue:before {
        background: #0072BA;
    }
    
    .green:before {
        background: #00701A;
    }
    
    .lightBlue:before {
        background: #009BD5;
    }
    
    .brown:before {
        background: #800000;
    }
    
    .orange:before {
        background: #FF7F00;
    }
    
    .purple:before {
        background: #94007C;
    }
    
    .yellow:before {
        background: #FFDF00;
    }
    
    .gray:before {
        background: #A2A5B5;
    }
    
    .lime:before {
        background: #8AD02A;
    }
    
    .teal:before {
        background: #7ACDCE;
    }
    
    .acbfe3:before {
        background: #ACBFE3;
    }
    
    .pink:before {
        background: #DB0A5B;
    }
    
    .fa6f9e:before {
        background: #FA6F9E;
    }
}