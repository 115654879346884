@media screen and (max-width: 1160px) {
    .container {
        padding: 0 15px;
    }
    
    .sidebar {
        display: flex;
        width: auto;
        align-items: center;
        justify-content: center;
        
        button {
            margin: 0;
        }
        
        div {
            margin-bottom: 0;
        }
    }
    
    .topics {
        width: 100%!important;
    }
}

@media screen and (max-width: 900px) {
    .topic {
        .topic__user-info {
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 20px;
        }
        
        .topic__time {
            margin-bottom: 10px;
        }
        
        .label {
            width: fit-content;
            margin-bottom: 10px;
        }
        
        .metrics {
            margin: 0;
        }
    }
    .sidebar {
        display: none;
    }
}