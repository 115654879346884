.filters, .modal-create, .auth {
    padding: 50px 0;
    
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        
        &--center {
            justify-content: flex-end;
        }
    }
    
    &__field {
        width: 326px;
        margin-bottom: 19px;
        
        label {
            display: block;
            margin-bottom: 5px;
        }
        
        input {
            width: 100%;
        }
        
        &_address {
            width: 580px;
        }
        
        &_highlight {
            color: #36b771;
        }
        
        &_mini {
            width: 285px;
            margin-right: 10px;
            margin-bottom: 0;
            
            &:last-child {
                margin-right: 0;
            }
        }
        
        &-auto {
            width: auto;
        }
    }
    
    &__button {
        width: 100%;
        
        &:hover {
            cursor: pointer;
        }
        
        &-reset {
            margin-right: 10px;
            background: #444645;
            
            &:hover {
                background: #444645;    
            }
        }
    }
}