.image {
    &__controls {
        margin-top: 10px;
    }

    &__images {
        margin-top: 25px;

        &_list {
            margin-top: 35px;
        }
    }

    &__img {
        display: inline-block;
        margin-top: 25px;
        margin-right: 40px;

        a {
            display: inline-block;
            position: relative;
        }

        img {
            width: 105px;
            height: 105px;
            object-fit: contain;
        }
    }


    &__cancel {
        position: absolute;
        top: -34px;
        right: -18px;
        font-size: 2.8rem;
    }
}