* {
    box-sizing: border-box;
}

html {
    font-size: 15px;
}

body {
    font-family: $fontFamily;
    font-weight: 400;
    color: $black;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    color: #000000;
    margin: 0;
    padding: 0;
}

h3 {
    font-size: 2.1rem;
}

ul, li {
    display: block;
    margin: 0;
    padding: 0;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.none-decor {
    text-decoration: none;
}

.inherit {
    color: inherit;
}

input[type="text"], input[type="email"], input[type="password"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.container {
    max-width: 1140px;
    margin: 0 auto;
}

main {
    padding-bottom: 90px;
}

.button {
    font-size: 1.07rem;
    background: $green;
    padding: 12px 22px;
    border-radius: 10px;
    color: $white;
    transition: .2s all ease-in;
    
    &:hover {
        background: #466760;
    }
    
    &:focus {
        outline: none;
    }
    
    &:disabled {
        cursor: default!important;
        background: #80b8ac;
    }
}

div.field {
    
}

input.field, textarea.field {
    font-family: $fontFamily;
    font-size: 1.15rem;
    border: 1px solid #D6D6D6;
    border-radius: 3px;
    padding: 9px 10px;
    transition: .2s all ease-in;
    
    &:focus, &:active {
        border: 1px solid #c4c4c4;
        outline: none;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    }

    &::placeholder {
        font-size: 1.13rem;
        font-weight: normal;
    }
}

label.field {
    color: #5E5E5E;
    font-size: 1.2rem;
}


.button-link {
    border: none;
    text-decoration: none;
    
    &:hover {
        cursor: pointer;
    }
}